<template>
    <div class="taskmanage">
        <div class="sn_table_group">
            <sn-search class="sn-search clearfix" :snSearch.sync="snSearch" @handleChange="handleChange"></sn-search>
            <div class="taskmanage_box">
                <div>
                    <hr>
                    <div id="monitor_data"></div>
                </div>
                <div style="margin-left:20px;">
                    <hr>
                    <div id="cj_person"></div>
                </div>
                <div>
                    <hr>
                    <div id="task_execution"></div>
                </div>
                <div style="margin-left:20px;">
                    <hr>
                    <div id="person_execution"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import data from './data_statistics.js';
import { mapActions } from 'vuex';
export default {
    name: 'taskmanage',
    data() {
        return {
            snSearch: data,
            monitor_data: null,
            cj_person: null,
            task_execution: null,
            person_execution: null,
            staticsData: {},
            startTime: '',
            endTime: '',
        };
    },
    mounted() {
        this.initData();
        this.initData_two();
        $(window).resize(() => {
            if (this.monitor_data) this.monitor_data.resize();
            if (this.cj_person) this.cj_person.resize();
            if (this.task_execution) this.task_execution.resize();
            if (this.person_execution) this.person_execution.resize();
        });
    },
    methods: {
        ...mapActions([
            'getStatistics',
            'getStatistics_zx'
        ]),
        handleChange(type, value) {
            this[type] = value[0];
            this.initData();
        },
        async getStaticdata(chartId) {
            let result = await this.getStatistics({
                startTime: this.startTime,
                endTime: this.endTime,
            });
            this.staticsData = result;
        },
        async initData() {
            await this.getStaticdata();
            this.initbarCharts('monitor_data');
            this.initbarCharts('cj_person');
        },
        async initData_two() {
            let result = await this.getStatistics_zx();
            this.executeData = result;
            this.initpieCharts();
            this.initbarCharts('person_execution');
        },
        getData(chartId) {
            let result = {};
            switch (chartId) {
                case 'monitor_data':
                    result = {
                        series: [{
                            type: 'bar',
                            barWidth: '30%',
                            data: this.staticsData.JCXTJ.map(item => Number(item.Cjjlsl))
                        }],
                        xAxis: this.staticsData.JCXTJ.map(item => item.Jcxmc),
                    };
                    break;
                case 'cj_person':
                    result = {
                        series: [{
                            type: 'bar',
                            barWidth: '30%',
                            data: this.staticsData.CJRTJ.map(item => Number(item.Cjjlsl)),
                        }],
                        xAxis: this.staticsData.CJRTJ.map(item => item.Cjrmc)
                    };
                    break;
                case 'task_execution':
                    result = {
                        series: this.executeData.AllTask.map(item => ({ value: item.value, name: item.Rwztmc })),
                        legend: this.executeData.AllTask.map(item => item.Rwztmc)
                    };
                    break;
                case 'person_execution':
                    result = {
                        series: [{
                            name: '已过期',
                            type: 'bar',
                            barWidth: '30%',
                            data: this.executeData.AllCollector.map(item => Number(item.Ygqsl))
                        }, {
                            name: '已完成',
                            type: 'bar',
                            barWidth: '30%',
                            data: this.executeData.AllCollector.map(item => Number(item.Ywcsl))
                        }, {
                            name: '未完成',
                            type: 'bar',
                            barWidth: '30%',
                            data: this.executeData.AllCollector.map(item => Number(item.Wwcsl))
                        }],
                        xAxis: this.executeData.AllCollector.map(item => item.Cjrmc),
                        legend: ['已过期', '已完成', '未完成']
                    };
                    break;
            }
            return result;
        },
        initbarCharts(chartId) {
            let myChart = this.$echarts.init(document.getElementById(chartId));
            let title = '';
            let color = '';
            let result = {};
            let all;
            let end;
            let max = 0;
            let allData;
            result = this.getData(chartId);
            allData = result.series;
            allData.forEach(item => {
                item.data.forEach(i => {
                    max = i > max ? i : max;
                });
            });
            switch (chartId) {
                case 'monitor_data':
                    title = '监测数据统计';
                    color = '#70AC47';
                    end = 100;
                    break;
                case 'cj_person':
                    title = '采集人员数据';
                    color = '#4373C4';
                    all = result.xAxis.length || 0;
                    end = all > 10 ? ((10 / all) * 100).toFixed(0) : 100;
                    break;
                case 'person_execution':
                    title = '采集人员执行情况统计';
                    color = ['#4373C4', '#70AC47', '#E97E36'];
                    all = result.xAxis.length || 0;
                    end = all > 6 ? ((6 / all) * 100).toFixed(0) : 100;
                    break;
            }
            let option = {
                title: {
                    text: title,
                    textStyle: {
                        color: '#193162',
                        fontSize: '16',
                    },
                    top: 7,
                    left: 5,
                },
                color: color,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: {
                            interval: 0,
                            formatter:function(val){
                                let step = 4;
                                let str = '';
                                let num = Math.ceil(val.length / step);
                                val.split('').forEach((item, i) => {
                                    if (i < num) {
                                        str += val.slice(step*i, step*(i+1)) + "\n";
                                    }
                                });
                                return str
                            },
                        },
                        data: result.xAxis,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        max: max,
                    }
                ],
                dataZoom: [{
                    show: end !== 100,
                    realtime: true,
                    height: 15,
                    bottom: 5,
                    start: 0,
                    end: end
                }, {
                    type: 'inside',
                    start: 0,
                    end: end
                }],
                legend: {
                    show: false,
                    data: result.legend,
                },
                series: this.setSeries(result.series),
            };
            myChart.setOption(option);
            this[chartId] = myChart;
        },
        setSeries(data) {
            let series = [];
            data.forEach(item => {
                series.push({
                    type: 'bar',
                    barMaxWidth: 30,
                    data: item.data,
                    name: item.name
                });
            });
            return series;
        },
        initpieCharts() {
            let myChart = this.$echarts.init(document.getElementById('task_execution'));
            let result = this.getData('task_execution');
            let option = {
                title: {
                    text: '总体任务执行情况',
                    textStyle: {
                        color: '#193162',
                        fontSize: '16',
                    },
                    top: 7,
                    left: 5,
                },
                color: ['#4373C4', '#70AC47', '#E97E36'],
                tooltip: {
                    trigger: 'item',
                    formatter: "{b}: {c} ({d}%)"
                },
                legend: {
                    y: 'bottom',
                    data: result.legend
                },
                series: [
                    {
                        type: 'pie',
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: false,
                                position: 'center'
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        data: result.series
                    }
                ]
            };
            myChart.setOption(option);
            this.task_execution = myChart;
        },
    },
};
</script>
<style lang="scss" scoped>
.taskmanage{
    .taskmanage_box{
        height: calc(100% - 50px);
        hr{
            position: absolute;
            top:30px;
            width:100%;
            background-color:#ccc;
            height:1px;
            border:none;
        }
        >div{
            position: relative;
            border: none;
            background-color: #fff;
            border-radius: 10px;
            -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, .1);
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, .1);
            display: inline-block;
            margin-top:20px;
            height:calc(50% - 20px);
            width:calc(50% - 10px);
            >div{
                width: 100%;
                height: 100%;
            }
        }
    }
}

</style>