const snSearch = {
    dateData: [{
        isShow: true,
        label: '起始时间',
        clearable: true,
        placeholder: '起始时间',
        operateType: 'startTime'
    }, {
        isShow: true,
        label: '终止时间',
        clearable: true,
        placeholder: '终止时间',
        operateType: 'endTime'
    }],
};
export default snSearch;